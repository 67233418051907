<template>
  <div>
    <div id="ar-scene-div" style="margin: 0; overflow: hidden;">

    </div>
  </div>
</template>

<script>

export default {
  mounted() {
    this.createARScene();
  },
  methods: {
    createARScene() {
      const sceneEl = document.createElement('a-scene');
      sceneEl.setAttribute('vr-mode-ui', 'enabled: false;');
      sceneEl.setAttribute('loading-screen', 'enabled: false;');
      sceneEl.setAttribute('arjs', 'trackingMethod: best; sourceType: webcam; debugUIEnabled: false;');
      sceneEl.setAttribute('embedded', '');
      sceneEl.setAttribute('gesture-detector', '');

      const markerEl = document.createElement('a-marker');
      markerEl.setAttribute('type', 'pattern');
      markerEl.setAttribute('preset', 'custom');
      markerEl.setAttribute('url', '/public/scanner/marker.patt');
      markerEl.setAttribute('raycaster', 'objects: .clickable');
      markerEl.setAttribute('emitevents', 'true');
      markerEl.setAttribute('cursor', 'fuse: false; rayOrigin: mouse;');

      const imageEl = document.createElement('a-image');
      imageEl.setAttribute('src', '/public/scanner/asset.png');
      imageEl.setAttribute('scale', '1 1 1');
      imageEl.setAttribute('class', 'clickable');
      imageEl.setAttribute('rotation', '-90 0 0');
      imageEl.setAttribute('gesture-handler', '');

      const camera = document.createElement('a-entity');
      camera.setAttribute('camera', '')

      markerEl.appendChild(imageEl);
      sceneEl.appendChild(markerEl);
      sceneEl.appendChild(camera);

      document.getElementById('ar-scene-div').appendChild(sceneEl);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
